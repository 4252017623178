import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { auth } from "../firebase";
import { Button } from "reactstrap";
import logo from ".././logo.png";
import slogan from ".././slogan.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun,faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import AuthContext from "./AuthContext"; // Import the context to check if a user is logged in

function Navbar({ mode, toggleMode }) {
  const currentUser = useContext(AuthContext); // Get the current user from context

  const handleSignOut = () => {
    auth.signOut().then(() => {
      console.log("User signed out");
    });
  };

  return (
    <nav className="navbar header">
      
      <img style={{ maxWidth: "30vh" }} src={logo} />
      {/* <img style={{ maxHeight: "3vh" }} src={slogan} /> */}
      <div style={{display:"flex", alignItems: "center"}}>
        <div onClick={toggleMode} className="link">
          {mode === "light" ? (
            <FontAwesomeIcon
              icon={faMoon}
              style={{
                cursor: "pointer",
                height: "25px",
                marginRight: "10px",
                color: "white",
              }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faSun}
              style={{
                cursor: "pointer",
                height: "25px",
                marginRight: "10px",
                color: "white",
              }}
            />
          )}
        </div>
        {currentUser && ( // Render the button only if there's a logged-in user
          <div  className="" size="sm" onClick={handleSignOut}>
           <FontAwesomeIcon
              icon={faSignOutAlt}
              style={{
                cursor: "pointer",
                height: "25px",
                marginRight: "10px",
                color: "white",
                marginLeft:"10px"
              }}
            />
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
