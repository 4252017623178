import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Alert,
  Spinner,
  Progress, Nav, NavItem, NavLink, TabPane, TabContent
} from "reactstrap";
import { db, storage, firebase } from "../../../firebase";
import DropdownComponent from "../../forms/DropdownComponent";
import MediaUpdate from "../../forms/MediaUpdate";
import TextInput from "../../forms/TextInput";
import DateInput from "../../forms/DateInput";
import RichEditor from "../../forms/RichEditor";
import {
  addToUnsubmittedMediaUrls,
  removeFromUnsubmittedMediaUrls,
  deleteFromStorage,
  generateFileName,
} from "../../utils/mediaUtils";

function UpdateArt({ itemData, refreshList, addNotification }) {
  const [name, setName] = useState(itemData.name);
  const [client, setClient] = useState(itemData.client.join(", "));
  const [description, setDescription] = useState(itemData.description);
  const [date, setDate] = useState(itemData.date);
  const [imageFiles, setImageFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [logoFiles, setLogoFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [videoPreviews, setVideoPreviews] = useState([]);
  const [logoPreviews, setLogoPreviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadingMedia, setUploadingMedia] = useState(null);
  const [uploadingIndex, setUploadingIndex] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [brief, setBrief] = useState(itemData.brief);
  const [selectedKeywords, setSelectedKeywords] = useState(itemData.keywords);
  const [dropdownValues, setDropdownValues] = useState({
    type: [],
    media: [],
    tools: [],
    keywords: [],
  });
  const [selectedTypes, setSelectedTypes] = useState(itemData.type);
  const [selectedTools, setSelectedTools] = useState(itemData.tools);
  const [selectedMedia, setSelectedMedia] = useState(itemData.media);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [activeTab, setActiveTab] = useState("1");


  const firestoreCollection = "arts";

  useEffect(() => {
    let isMounted = true;

    const fetchDropdownValues = async () => {
      try {
        const doc = await db
          .collection("dropdowns")
          .doc(firestoreCollection)
          .get();
        if (doc.exists && isMounted) {
          setDropdownValues(doc.data());
        }
      } catch (error) {
        console.error("Error fetching dropdown values:", error);
        addNotification(
          "Error fetching dropdown values. Please refresh the page.",
          "danger"
        );
      }
    };

    fetchDropdownValues();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const imageUrls = [...itemData.ImageUrl, ...imageFiles.map((file) => file.url)];
      const videoUrls = [...itemData.VideoUrl, ...videoFiles.map((file) => file.url)];
      const logoUrls = [...itemData.LogoUrl, ...logoFiles.map((file) => file.url)];

      const updatedItem = {
        name,
        type: selectedTypes,
        media: selectedMedia,
        client: client.split(",").map((item) => item.trim()),
        description,
        date,
        tools: selectedTools,
        ImageUrl: imageUrls,
        VideoUrl: videoUrls,
        LogoUrl: logoUrls,
        isOn: itemData.isOn,
        createdAt: itemData.createdAt,
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
        keywords: selectedKeywords,
        brief,
      };

      await db.collection(firestoreCollection).doc(itemData.id).update(updatedItem);

      await removeFromUnsubmittedMediaUrls([
        ...imageUrls,
        ...videoUrls,
        ...logoUrls,
      ]);

      if (refreshList) {
        refreshList();
      }
      addNotification("Item updated successfully", "success");

    } catch (error) {
      console.error("Error during form update:", error);
      addNotification("Error during form update. Please try again.", "danger");
    } finally {
      setIsLoading(false);
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Form className="footer" onSubmit={handleSubmit}>
<Nav tabs>
        <NavItem>
          <NavLink
            className={{ active: activeTab === "1" }}
            onClick={() => {
              toggle("1");
            }}
          >
            General
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={{ active: activeTab === "2" }}
            onClick={() => {
              toggle("2");
            }}
          >
            Media
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={{ active: activeTab === "3" }}
            onClick={() => {
              toggle("3");
            }}
          >
            Description
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
      <TabPane tabId="1">
      <TextInput
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Name"
      />
        <TextInput
        label="Client"
        value={client}
        onChange={(e) => setClient(e.target.value)}
        placeholder="Clients (comma separated, e.g. Developer, Designer)"
      />
      <DateInput
        label="Date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
      />
      <DropdownComponent
        label="Type"
        items={dropdownValues.type}
        selectedItems={selectedTypes}
        setSelectedItems={setSelectedTypes}
      />
      <DropdownComponent
        label="Media"
        items={dropdownValues.media}
        selectedItems={selectedMedia}
        setSelectedItems={setSelectedMedia}
      />
      <DropdownComponent
        label="Tools"
        items={dropdownValues.tools}
        selectedItems={selectedTools}
        setSelectedItems={setSelectedTools}
      />
      <DropdownComponent
        label="Keywords"
        items={dropdownValues.keywords}
        selectedItems={selectedKeywords}
        setSelectedItems={setSelectedKeywords}
      />
      </TabPane>
      <TabPane tabId="2">
      <MediaUpdate
        label="Images"
        type="images"
        files={imageFiles}
        setFiles={setImageFiles}
        previews={[...imagePreviews, ...itemData.ImageUrl]}
        setPreviews={setImagePreviews}
        addNotification={addNotification}
        setUploadingMedia={setUploadingMedia}
        setUploadingIndex={setUploadingIndex}
        setUploadProgress={setUploadProgress}
        deleteFromStorage={deleteFromStorage}
        removeFromUnsubmittedMediaUrls={removeFromUnsubmittedMediaUrls}
        storage={storage}
        generateFileName={generateFileName}
        addToUnsubmittedMediaUrls={addToUnsubmittedMediaUrls}
        setImageFiles={setImageFiles}
        firestoreCollection={firestoreCollection}
        itemData={itemData}
        setForceUpdate={setForceUpdate}
      />
      <MediaUpdate
        label="Videos"
        type="videos"
        files={videoFiles}
        setFiles={setVideoFiles}
        previews={[...videoPreviews, ...itemData.VideoUrl]}
        setPreviews={setVideoPreviews}
        addNotification={addNotification}
        setUploadingMedia={setUploadingMedia}
        setUploadingIndex={setUploadingIndex}
        setUploadProgress={setUploadProgress}
        deleteFromStorage={deleteFromStorage}
        removeFromUnsubmittedMediaUrls={removeFromUnsubmittedMediaUrls}
        storage={storage}
        generateFileName={generateFileName}
        addToUnsubmittedMediaUrls={addToUnsubmittedMediaUrls}
        setVideoFiles={setVideoFiles}
        firestoreCollection={firestoreCollection}
        itemData={itemData}
        setForceUpdate={setForceUpdate}
      />
      <MediaUpdate
        label="Logos"
        type="logos"
        files={logoFiles}
        setFiles={setLogoFiles}
        previews={[...logoPreviews, ...itemData.LogoUrl]}
        setPreviews={setLogoPreviews}
        addNotification={addNotification}
        setUploadingMedia={setUploadingMedia}
        setUploadingIndex={setUploadingIndex}
        setUploadProgress={setUploadProgress}
        deleteFromStorage={deleteFromStorage}
        removeFromUnsubmittedMediaUrls={removeFromUnsubmittedMediaUrls}
        storage={storage}
        generateFileName={generateFileName}
        addToUnsubmittedMediaUrls={addToUnsubmittedMediaUrls}
        setLogoFiles={setLogoFiles}
        firestoreCollection={firestoreCollection}
        itemData={itemData}
        setForceUpdate={setForceUpdate}
      />
      </TabPane>
      <TabPane tabId="3">
      <TextInput
        label="Brief"
        value={brief}
        onChange={(e) => setBrief(e.target.value)}
        placeholder="Brief description"
      />
      <RichEditor
        label="Description"
        value={description}
        onChange={setDescription}
      />
      </TabPane>
      </TabContent>
    <Button
        className="mt-3 primary"
        // color="primary"
        type="submit"
        disabled={isLoading || uploadingMedia}
      >
         {isLoading ? <Spinner size="sm" color="light" /> : "Update"}
      </Button>
      
      {uploadingMedia && (
        <Alert className="mt-2 primary">
          <Spinner /> Uploading {uploadingMedia}{" "}
          {uploadingIndex !== null && `${uploadingIndex}`}, please wait...
          <Progress className="mt-2" value={uploadProgress} max={100} />
        </Alert>
      )}
    </Form>
  );
}

export default UpdateArt;
