import React from 'react'
import {FormGroup, Label, Input} from 'reactstrap';

function TextInput({ label, value, onChange, placeholder }) {
  return (
    <FormGroup>
      <Label className='font-2'>{label}</Label>
      <Input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </FormGroup>
  );
}

export default TextInput