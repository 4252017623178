import React from 'react';
import { Row, Col } from "reactstrap";
import CustomAccordion from "./Accordion";

function Filters({
  filters,
  selectedFilters,
  setSelectedFilters,
  items,
  isFilterOpen,
  setIsFilterOpen
}) {

  const toggleFilterAccordion = (currentOpenId) => {
    if (currentOpenId === "filters") {
      setIsFilterOpen(!isFilterOpen);
    }
  };

  const applyLocalFilters = () => {
    let filteredItems = items;

    Object.keys(selectedFilters).forEach((category) => {
      if (selectedFilters[category].length > 0) {
        filteredItems = filteredItems.filter((item) =>
          selectedFilters[category].every(
            (filter) => item[category] && item[category].includes(filter)
          )
        );
      }
    });

    return filteredItems;
  };

  const handleCheckboxChange = (category, value) => {
    const updatedFilters = {
      ...selectedFilters,
      [category]: selectedFilters[category].includes(value)
        ? selectedFilters[category].filter((item) => item !== value)
        : [...selectedFilters[category], value],
    };

    setSelectedFilters(updatedFilters);
    applyLocalFilters(); // Use the updated filters directly
  };

  return (
    <CustomAccordion
      title="Filters"
      flush
      className="mt-2 label"
      open={isFilterOpen ? ["filters"] : []}
      toggle={() => toggleFilterAccordion("filters")}
      style={{ borderSize: 0 }}
    >
      <Row>
        {Object.keys(filters)
          .sort((a, b) => a.localeCompare(b)) // Sort the filter categories alphabetically
          .map((filterCategory) => (
          <Col xs="auto" key={filterCategory}>
            <h5>
              {filterCategory.charAt(0).toUpperCase() +
                filterCategory.slice(1)}
            </h5>
            {filters[filterCategory].map((value) => (
              <div key={value}>
                <input
                  type="checkbox"
                  checked={
                    selectedFilters[filterCategory] &&
                    selectedFilters[filterCategory].includes(value)
                  }
                  onChange={() =>
                    handleCheckboxChange(filterCategory, value)
                  }
                />
                <label className="m-1 font-2">{value}</label>
              </div>
            ))}
          </Col>
        ))}
      </Row>
    </CustomAccordion>
  );

}

export default Filters;
