// RichEditor.js
import React from 'react';
import { FormGroup, Label } from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function RichEditor({ label, value, onChange }) {
  return (
    <FormGroup>
      <Label className='font-2' for="description">{label}</Label>
      <ReactQuill value={value} onChange={onChange} />
    </FormGroup>
  );
}

export default RichEditor;
