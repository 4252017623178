import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
// import AddDropdown from './AddDropdown'; // 
import DropdownManager from './DropdownManager';
import Visibilities from '../../VisibilityManager';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEye, faGears} from "@fortawesome/free-solid-svg-icons";


function Settings({ refreshDropdowns, addNotification, firestoreCollection }) {
  const [activeTab, setActiveTab] = useState('1');

  const toggleSettings = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    
    <div>
      {console.log("settings", firestoreCollection)}
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => toggleSettings('1')}
          >
            <FontAwesomeIcon icon={faEye} /> Visibility
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => toggleSettings('2')}
          >
            <FontAwesomeIcon icon={faGears} /> Values
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
         <Visibilities addNotification={addNotification} firestoreCollection={firestoreCollection}/>
        </TabPane>
        <TabPane tabId="2">
        <DropdownManager refreshDropdowns={refreshDropdowns} firestoreCollection={firestoreCollection}/>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default Settings;
