import React from 'react'
import {cleanupUnsubmittedMedia} from './utils/firebaseUtils'
import {Button} from 'reactstrap';

function CleanUp(addNotification) {
  return (

    
    <div>
        Cleaning up the storage.
        <Button onClick={() => cleanupUnsubmittedMedia(addNotification)} />
    </div>
  )
}

export default CleanUp