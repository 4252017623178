import React, { useState, useEffect } from "react";
import { Button, FormGroup, Label, Input } from "reactstrap";
import { db } from "../../../../firebase";
import firebase from "firebase/compat/app";

const firestoreCollection = "apps";

function UpdateManager() {
  const [dropdowns, setDropdowns] = useState({
    tools: [],
    media: [],
    type: [],
    keywords: [],
  });
  const [type, setType] = useState("");
  const [tool, setTool] = useState("");
  const [media, setMedium] = useState("");
  const [keyword, setKeyword] = useState("");

  const fetchDropdowns = async () => {
    const doc = await db.collection("dropdowns").doc(firestoreCollection).get();
    if (doc.exists) {
      setDropdowns(doc.data());
    }
  };

  useEffect(() => {
    fetchDropdowns();
  }, []);

  const handleSubmit = async (field) => {
    // Fetch current dropdown values
    const doc = await db.collection("dropdowns").doc(firestoreCollection).get();
    if (!doc.exists) {
      console.error("Document does not exist!");
      return;
    }
    const dropdownData = doc.data();
  
    let valueToAdd;
    switch (field) {
      case "type":
        valueToAdd = type;
        break;
      case "tools":
        valueToAdd = tool;
        break;
      case "media":
        valueToAdd = media;
        break;
      case "keywords":
        valueToAdd = keyword;
        break;
      default:
        valueToAdd = "";
    }
  
    if (valueToAdd) {
      if (dropdownData[field] && dropdownData[field].includes(valueToAdd)) {
        alert(`This ${field} already exists!`);
        return;
      }
      await db.collection("dropdowns").doc(firestoreCollection).update({
        [field]: firebase.firestore.FieldValue.arrayUnion(valueToAdd),
      }
      );
      fetchDropdowns();
      // Reset the input field
      switch (field) {
        case "type":
          setType("");
          break;
        case "tools":
          setTool("");
          break;
        case "media":
          setMedium("");
          break;
        case "keywords":
          setKeyword("");
          break;
        default:
          break;
      }
    }
  };
  

  const handleDelete = async (field, value) => {
    if (!window.confirm("Are you sure you want to delete this?")) {
      return;
    }
    await db.collection("dropdowns").doc(firestoreCollection).update({
      [field]: firebase.firestore.FieldValue.arrayRemove(value),
    });
    setDropdowns((prev) => ({
      ...prev,
      [field]: prev[field].filter((item) => item !== value),
    }));
  };

  const fixedOrder = ["type", "media", "tools", "keywords"]; 

  return (
    <div>
    {fixedOrder.map((key) => (
      <div className="mt-3" key={key}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
          <h4 style={{ marginRight: "10px" }}>
            {key.charAt(0).toUpperCase() + key.slice(1)}
          </h4>
          {key === "tools" && (
            <>
              <Input
                type="text"
                value={tool}
                onChange={(e) => setTool(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              <Button className="primary" onClick={() => handleSubmit("tools")}>
                +
              </Button>
            </>
          )}
          {key === "media" && (
            <>
              <Input
                type="text"
                value={media}
                onChange={(e) => setMedium(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              <Button className="primary" onClick={() => handleSubmit("media")}>
                +
              </Button>
            </>
          )}
          {key === "type" && (
            <>
              <Input
                type="text"
                value={type}
                onChange={(e) => setType(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              <Button className="primary" onClick={() => handleSubmit("type")}>
                +
              </Button>
            </>
          )}
          {key === "keywords" && (
            <>
              <Input
                type="text"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              <Button className="primary" onClick={() => handleSubmit("keywords")}>
                +
              </Button>
            </>
          )}
        </div>
        <div>
          {dropdowns[key] && dropdowns[key].map((value) => (
            <div key={value} style={{ display: "inline-block", margin: "5px" }}>
              <Button className="primary">
                {value}
                <span
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                  onClick={() => handleDelete(key, value)}
                >
                  X
                </span>
              </Button>
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
  );
}

export default UpdateManager;
