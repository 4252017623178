import React, {useState} from 'react'
import {Input, Label, CarouselItem, Carousel, CarouselControl, CarouselIndicators} from 'reactstrap'
import { generateFileName, deleteFromStorage } from "../utils/mediaUtils.js";
import { db, firebase } from '../../firebase.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faVideo, faStar } from "@fortawesome/free-solid-svg-icons";

function MediaUpdate({  
  firestoreCollection,
  label,
  type,
  files,
  setFiles,
  previews,
  setPreviews,
  addNotification,
  setUploadingMedia,
  setUploadingIndex,
  setUploadProgress,
  deleteFromStorage,
  removeFromUnsubmittedMediaUrls,
  storage,
  addToUnsubmittedMediaUrls,
  setImageFiles,
  setVideoFiles,
  setLogoFiles,

    itemData,
    setForceUpdate,

}) 

{
  
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const handleMediaChange = async (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      setFiles((prevFiles) => [...prevFiles, ...filesArray]);
      setPreviews((prevURLs) => [
        ...prevURLs,
        ...filesArray.map((file) => URL.createObjectURL(file)),
      ]);

      for (let i = 0; i < filesArray.length; i++) {
        await handleImmediateUpload(filesArray[i], type, i);
      }
    }
  };

  const handleMediaDelete = async (indexToDelete) => {
    if (!window.confirm("Are you sure you want to delete this?")) {
      return;
    }
    const fileToDelete = files[indexToDelete];
    let urlToDelete;
  
    if (fileToDelete) {
      urlToDelete = fileToDelete.url;
      setFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToDelete));
    } else {
      urlToDelete = previews[indexToDelete];
    }
  
    try {
      await deleteFromStorage(urlToDelete);
      setPreviews((prevURLs) => prevURLs.filter((_, index) => index !== indexToDelete));
      removeFromUnsubmittedMediaUrls(urlToDelete);
      addNotification("Media deleted successfully", "success");
    } catch (error) {
      console.error("Error deleting media from storage:", error);
      addNotification("Error while deleting media. Please try again.", "danger");
    }
  
    let fieldName;
    if (type === "images") {
      fieldName = "ImageUrl";
    } else if (type === "videos") {
      fieldName = "VideoUrl";
    } else if (type === "logos") {
      fieldName = "LogoUrl";
    }
  
    if (itemData && itemData.id) {
      try {
        await db.collection(firestoreCollection).doc(itemData.id).update({
          [fieldName]: firebase.firestore.FieldValue.arrayRemove(urlToDelete),
        });
      } catch (error) {
        console.error("Error updating Firestore:", error);
        addNotification("Error while deleting media. Please try again.", "danger");
      }
    } else {
      console.error("itemData or itemData.id is undefined. Cannot update Firestore.");
    }
  
    const updatedImageUrl = itemData.ImageUrl.filter(url => url !== urlToDelete);
    itemData.ImageUrl = updatedImageUrl;
    setForceUpdate();
  
    // Adjust the active index if the deleted item was the last one
    if (indexToDelete === previews.length - 1 && activeIndex === indexToDelete) {
      setActiveIndex(prevIndex => prevIndex - 1);
    }
  };
  
  
  
  
  const handleImmediateUpload = async (file, type, index,) => {
    setUploadingMedia(type);
    setUploadingIndex(index); 

    const fileRef = storage.ref(generateFileName(file, type, index, firestoreCollection));
    const uploadTask = fileRef.put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        console.error("Upload error:", error);
      }
    );

    await uploadTask;
    const downloadURL = await fileRef.getDownloadURL();

    // Add the URL to the unsubmittedMediaUrls document
    await addToUnsubmittedMediaUrls(downloadURL);

    // Store the access URL in the relevant state
    if (type === "images") {
      setImageFiles((prevFiles) =>
        prevFiles.map((f) => (f === file ? { ...f, url: downloadURL } : f))
      );
    } else if (type === "videos") {
      setVideoFiles((prevFiles) =>
        prevFiles.map((f) => (f === file ? { ...f, url: downloadURL } : f))
      );
    } else if (type === "logos") {
      setLogoFiles((prevFiles) =>
        prevFiles.map((f) => (f === file ? { ...f, url: downloadURL } : f))
      );
    }

    setUploadingMedia(null);
    setUploadingIndex(null);
  };

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === previews.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? previews.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = previews.map((src, index) => (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={index}
    >
      <div style={{ position: 'relative' }}>
        {type === "videos" ? (
          <video
            src={src}
            alt="Video Preview"
            style={{ width: "600px", height: '100%' }}
            controls
          />
        ) : (
          <img src={src} alt="Preview" style={{ width: "600px", height: '100%' }} />
        )}
        <button
          type="button"
          style={{
            position: "absolute",
            top: "10%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "red",
            color: "white",
            border: "none",
            cursor: "pointer",
            borderRadius: '20%',
            padding: '5px 10px'
          }}
          onClick={() =>
            handleMediaDelete(index, files, setFiles, setPreviews)
          }
        >
          X
        </button>
      </div>
    </CarouselItem>
  ));
  
  

  let icon;
  switch (type) {
    case "images":
      icon = faImage;
      break;
    case "videos":
      icon = faVideo;
      break;
    case "logos":
      icon = faStar;
      break;
    default:
      icon = faStar;
  }

  return (
    <div className="mt-3 d-flex align-items-center">
    <div>
      <Label className="font-2" style={{ marginLeft: 20 }}>{label}</Label>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <label
          style={{
            display: "inline-block",
            width: "90px",
            height: "90px",
            borderRadius: "10%",
            borderWidth: 2,
            borderColor: "#000",
            border: "solid",
            textAlign: "center",
            lineHeight: "100px",
            cursor: "pointer",
            margin: 20
          }}
        >
          <FontAwesomeIcon icon={icon} size="2x" />
          <Input
            className="label"
            type="file"
            name={type}
            id={type}
            multiple
            style={{ display: "none" }}
            onChange={(e) => {
              if (type === "images") {
                handleMediaChange(e, setFiles, setPreviews, type);
              } else if (type === "videos") {
                handleMediaChange(e, setFiles, setPreviews, type);
              } else if (type === "logos") {
                handleMediaChange(e, setFiles, setPreviews, type);
              }
            }}
          />
        </label>
      </div>
    </div>
  
    <div style={{ marginLeft: '20px', maxWidth: '600px' }}>
    <Carousel
    activeIndex={activeIndex}
    next={next}
    previous={previous}
  >
    {previews.length > 1 && <CarouselIndicators items={previews} activeIndex={activeIndex} onClickHandler={goToIndex} />}
    {slides}
    {previews.length > 1 && <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />}
    {previews.length > 1 && <CarouselControl direction="next" directionText="Next" onClickHandler={next} />}
  </Carousel>
    </div>
  </div>
  );
}


export default MediaUpdate