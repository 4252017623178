import React from 'react'

const Loading = () => {
    return (
        <div className="loading-container">
            <i style={{color:"#cc7b34", backgroundColor:"#303030"}} className=" fas fa-cog fa-spin"></i>
            <p>Loading...</p>
        </div>
    );
}

export default Loading