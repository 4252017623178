import React, {useState} from 'react'
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Label} from 'reactstrap'

function DropdownComponent({   label,
  items = [],
  selectedItems,
  setSelectedItems }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const handleDropdownSelect = (value, setSelectedFunction) => {
    setSelectedFunction((prev) => [...prev, value]);
  };

  const handleDropdownDelete = (value, setSelectedFunction) => {
    setSelectedFunction((prev) => prev.filter((item) => item !== value));
  };

  return (
    <div className='mt-3'>
       <Label className='font-2'>{label}</Label>
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle className="label" caret>
          Select Item
        </DropdownToggle>
        <DropdownMenu>
          {items.map((item, index) => (
            <DropdownItem key={index} onClick={() => handleDropdownSelect(item, setSelectedItems)}
            >
              {item}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      <div>
        {selectedItems.map((item, index) => (
          <Button key={index} type="button" className="m-1 primary">
            {item}
            <span
              onClick={() => handleDropdownDelete(item, setSelectedItems)}
              style={{ marginLeft: "5px", cursor: "pointer" }}
            >
              X
            </span>
          </Button>
        ))}
      </div>
    </div>
  );
}


export default DropdownComponent