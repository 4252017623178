import React from 'react';
import { Link, useLocation } from "react-router-dom";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { faUserGraduate, faBriefcase, faBellConcierge, faWrench, faAward, faPalette, faCode, faFolderClosed, faGamepad, faPencil} from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Sidebar() {
  const location = useLocation();

  const isActive = (path) => location.pathname.includes(path);

  return (
    <div className="sidebar label">
      <ListGroup flush>
        <ListGroupItem className="label" tag="strong">Personal</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/education")} tag={Link} to="/dashboard/education"><FontAwesomeIcon icon={faUserGraduate} />{"  "}Education</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/workexperience")} tag={Link} to="/dashboard/workexperience"><FontAwesomeIcon icon={faBriefcase} />{"  "}Experience</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/expertises")} tag={Link} to="/dashboard/expertises"><FontAwesomeIcon icon={faBellConcierge} />{"  "}Expertises</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/skills")} tag={Link} to="/dashboard/skills"><FontAwesomeIcon icon={faWrench} />{"  "}Skills</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/achievements")} tag={Link} to="/dashboard/achievements"><FontAwesomeIcon icon={faAward} />{"  "}Achievements</ListGroupItem>
      </ListGroup>

      <ListGroup flush>
        <ListGroupItem className="label" tag="strong">Works</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/digitalarts")} tag={Link} to="/dashboard/digitalarts"><FontAwesomeIcon icon={faPalette} />{"  "}Arts</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/webapps")} tag={Link} to="/dashboard/webapps"><FontAwesomeIcon icon={faCode} />{"  "}Apps</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/projects")} tag={Link} to="/dashboard/projects"><FontAwesomeIcon icon={faFolderClosed} />{"  "}Projects</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/games")} tag={Link} to="/dashboard/games"><FontAwesomeIcon icon={faGamepad} />{"  "}Games</ListGroupItem>
      </ListGroup>

      <ListGroup flush>
        <ListGroupItem className="label" tag="strong">Blogs</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/blogs")} tag={Link} to="/dashboard/blogs"><FontAwesomeIcon icon={faPencil} />{"  "}Blogs</ListGroupItem>
      </ListGroup>
    </div>
  );
}

export default Sidebar;
