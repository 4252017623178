import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Tabs from '../Tabs';
import Sidebar from '../Sidebar';
import Dashboard from '../Dashboard';

function Main(props) {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1225px)'
  });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  return (
    <div>
      {isDesktopOrLaptop && (
        <div className="flex-container ">
          <div className="sidebar label">
            <Sidebar />
          </div>
          <div className="main-content background">
            <Dashboard />
          </div>
        </div>
      )}
      {isTabletOrMobile && (
        <div className="mobile-container ">
          <div className="mobile-navbar label">
            <Tabs />
          </div>
          <div className="mobile-content background">
           < Dashboard />
          </div>
        </div>
      )}
    </div>
  );
}

export default Main;
