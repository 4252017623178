import React from 'react';
import { Alert } from 'reactstrap';

function Notification({ message, type, onClose }) {
  return (
    <Alert color={type} isOpen={true} toggle={onClose}>
      {message}
    </Alert>
  );
}

export default Notification;
