import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import AddDropdown from './AddDropdown'; // Assuming you named the previous component as DropdownsForm
import UpdateDropdown from './UpdateDropdown';

function DropdownsManager() {
  const [activeTab, setActiveTab] = useState('1');

  const toggleSettings = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => toggleSettings('1')}
          >
            Visibility
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => toggleSettings('2')}
          >
            Values
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <AddDropdown />
        </TabPane>
        <TabPane tabId="2">
        <UpdateDropdown />
        </TabPane>
      </TabContent>
    </div>
  );
}

export default DropdownsManager;
