import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Alert, Spinner, Progress } from 'reactstrap';
import ReactQuill from 'react-quill';
import { db, storage, firebase } from '../../../firebase';

function UpdateArt({ itemData, onUpdateSuccess, refreshList, addNotification }) {
  const [name, setName] = useState(itemData.name || "");
  const [type, setType] = useState(Array.isArray(itemData.type) ? itemData.type.join(', ') : "");
  const [medium, setMedium] = useState(Array.isArray(itemData.medium) ? itemData.medium.join(', ') : "");
  const [client, setClient] = useState(Array.isArray(itemData.client) ? itemData.client.join(', ') : "");
  const [tools, setTools] = useState(Array.isArray(itemData.tools) ? itemData.tools.join(', ') : "");  
    const [description, setDescription] = useState(itemData.description);
    const [date, setDate] = useState(itemData.date);
    const [imageFiles, setImageFiles] = useState([]);
    const [imagePreviews, setImagePreviews] = useState(itemData.ImageUrl || []);
    const [logoFiles, setLogoFiles] = useState([]);
    const [logoPreviews, setShowcasePreviews] = useState(itemData.LogoUrl || []);
    const [videoFiles, setVideoFiles] = useState([]);
    const [videoPreviews, setVideoPreviews] = useState(itemData.VideoUrl || []);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadingMedia, setUploadingMedia] = useState(null);
    const [uploadingIndex, setUploadingIndex] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);



    const generateFileName = (file, type, index) => {
        const currentDate = new Date().toISOString();
        const extension = file.name.split(".").pop();
        return `arts/${type}/${file.name}+${currentDate}+${index}.${extension}`;
    };

    const handleImmediateUpload = async (file, type, index) => {
        setUploadingMedia(type);
        setUploadingIndex(index);

        const fileRef = storage.ref(generateFileName(file, type, index));
        const uploadTask = fileRef.put(file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
            },
            (error) => {
                console.error("Upload error:", error);
            }
        );

        await uploadTask;
        const downloadURL = await fileRef.getDownloadURL();

        if (type === "images") {
            setImageFiles(prevFiles => prevFiles.map(f => f === file ? { ...f, url: downloadURL } : f));
        } else if (type === "videos") {
            setVideoFiles(prevFiles => prevFiles.map(f => f === file ? { ...f, url: downloadURL } : f));
        } else if (type === "logos") {
            setLogoFiles(prevFiles => prevFiles.map(f => f === file ? { ...f, url: downloadURL } : f));
        }

        setUploadingMedia(null);
        setUploadingIndex(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const imageUrls = imageFiles.map(file => file.url);
        const videoUrls = videoFiles.map(file => file.url);
        const logoUrls = logoFiles.map(file => file.url);

        const updateItem = {
            name,
            type: type.split(",").map(item => item.trim()),
            media: medium.split(",").map(item => item.trim()),
            client: client.split(",").map(item => item.trim()),
            description,
            date,
            tools: tools.split(",").map(item => item.trim()),
            ImageUrl: [...imagePreviews.filter(url => !url.startsWith('blob:')), ...imageUrls],
            VideoUrl: [...videoPreviews.filter(url => !url.startsWith('blob:')), ...videoUrls],
            LogoUrl: [...logoPreviews.filter(url => !url.startsWith('blob:')), ...logoUrls],
            modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
        };

        await db.collection("arts").doc(itemData.id).update(updateItem);
        onUpdateSuccess();
        addNotification("Item updated successfully","success");
        refreshList();
        
        
        setIsLoading(false);
    };

    const deleteFromStorage = async (url) => {
      try {
        const fileRef = storage.refFromURL(url);
        await fileRef.delete();
        addNotification("Media deleted from storage successfully", "success");
      } catch (error) {
        if (error.code === "storage/object-not-found") {
          console.warn("Media already deleted or not found:", url);
        } else {
          console.error("Error deleting media:", error);
        }
      }
    };
    

    const handleImageDelete = async (indexToDelete) => {
        if (!window.confirm("Are you sure you want to delete this?")) {
            return;
        }
        const fileToDelete = imageFiles[indexToDelete];
        let urlToDelete;
        
        if (fileToDelete) {
            urlToDelete = fileToDelete.url;
            await deleteFromStorage(urlToDelete); // Delete using the URL
            setImageFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToDelete));
        } else {
            urlToDelete = imagePreviews[indexToDelete];
            await deleteFromStorage(urlToDelete);
        }
        
        setImagePreviews(prevURLs => prevURLs.filter((_, index) => index !== indexToDelete));
    
        // Update Firestore
        await db.collection("arts").doc(itemData.id).update({
            ImageUrl: firebase.firestore.FieldValue.arrayRemove(urlToDelete)
        });
    };
    
    
    const handleVideoDelete = async (indexToDelete) => {
        if (!window.confirm("Are you sure you want to delete this?")) {
            return;
        }
        const fileToDelete = videoFiles[indexToDelete];
        let urlToDelete;
        
        if (fileToDelete) {
            urlToDelete = fileToDelete.url;
            await deleteFromStorage(urlToDelete); // Delete using the URL
            setVideoFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToDelete));
        } else {
            urlToDelete = videoPreviews[indexToDelete];
            await deleteFromStorage(urlToDelete);
        }
        
        setVideoPreviews(prevURLs => prevURLs.filter((_, index) => index !== indexToDelete));
    
        // Update Firestore
        await db.collection("arts").doc(itemData.id).update({
            VideoUrl: firebase.firestore.FieldValue.arrayRemove(urlToDelete)
        });
    };
    
    
    const handleShowcaseDelete = async (indexToDelete) => {
        if (!window.confirm("Are you sure you want to delete this?")) {
            return;
        }
        const fileToDelete = logoFiles[indexToDelete];
        let urlToDelete;
        
        if (fileToDelete) {
            urlToDelete = fileToDelete.url;
            await deleteFromStorage(urlToDelete); // Delete using the URL
            setLogoFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToDelete));
        } else {
            urlToDelete = logoPreviews[indexToDelete];
            await deleteFromStorage(urlToDelete);
        }
        
        setShowcasePreviews(prevURLs => prevURLs.filter((_, index) => index !== indexToDelete));
    
        // Update Firestore
        await db.collection("arts").doc(itemData.id).update({
            LogoUrl: firebase.firestore.FieldValue.arrayRemove(urlToDelete)
        });
    };    

    const handleImageChange = async (e) => {
        if (e.target.files) {
          const filesArray = Array.from(e.target.files);
          setImageFiles((prevFiles) => [...prevFiles, ...filesArray]);
          setImagePreviews((prevURLs) => [
            ...prevURLs,
            ...filesArray.map((file) => URL.createObjectURL(file)),
          ]);
    
          for (let i = 0; i < filesArray.length; i++) {
            await handleImmediateUpload(filesArray[i], "images", i);
          }
        }
      };
    
      const handleVideoChange = async (e) => {
        if (e.target.files) {
          const filesArray = Array.from(e.target.files);
          setVideoFiles((prevFiles) => [...prevFiles, ...filesArray]);
          setVideoPreviews((prevURLs) => [
            ...prevURLs,
            ...filesArray.map((file) => URL.createObjectURL(file)),
          ]);
    
          for (let i = 0; i < filesArray.length; i++) {
            await handleImmediateUpload(filesArray[i], "videos", i);
          }
        }
      };
    
      const handleShowcaseChange = async (e) => {
        if (e.target.files) {
          const filesArray = Array.from(e.target.files);
          setLogoFiles((prevFiles) => [...prevFiles, ...filesArray]);
          setShowcasePreviews((prevURLs) => [
            ...prevURLs,
            ...filesArray.map((file) => URL.createObjectURL(file)),
          ]);
    
          for (let i = 0; i < filesArray.length; i++) {
            await handleImmediateUpload(filesArray[i], "logos", i);
          }
        }
      };

    useEffect(() => {
        return () => {
            [...imagePreviews, ...logoPreviews, ...videoPreviews].forEach((url) => URL.revokeObjectURL(url));
        };
    }, [imagePreviews, logoPreviews, videoPreviews]);
    return (
        <Form onSubmit={handleSubmit}>
     
      <FormGroup>
        <Label for="name">Name</Label>
        <Input
          type="text"
          name="name"
          id="name"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="type">Type</Label>
        <Input
          type="text"
          name="type"
          id="type"
          placeholder="Type (comma separated, e.g. PlayStation 4, Xbox One)"
          value={type}
          onChange={(e) => setType(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="medium">Medium</Label>
        <Input
          type="text"
          name="medium"
          id="medium"
          placeholder="Medium (comma separated, e.g. Action Adventure, Role Playing)"
          value={medium}
          onChange={(e) => setMedium(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="client">Client</Label>
        <Input
          type="text"
          name="client"
          id="client"
          placeholder="Client (comma separated, e.g. Developer, Designer)"
          value={client}
          onChange={(e) => setClient(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="tools">Tools</Label>
        <Input
          type="text"
          name="tools"
          id="tools"
          placeholder="Tools (comma separated, e.g. Unity, Blender)"
          value={tools}
          onChange={(e) => setTools(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="description">Description</Label>
        <ReactQuill value={description} onChange={setDescription} />
      </FormGroup>
      <FormGroup>
        <Label for="date">Date</Label>
        <Input
          type="date"
          name="date"
          id="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="images">Images</Label>
        <Input
          type="file"
          name="images"
          id="images"
          multiple
          onChange={handleImageChange}
        />
        <div style={{ marginTop: "10px" }}>
          {imagePreviews.map((src, index) => (
            <div
              key={index}
              style={{
                display: "inline-block",
                position: "relative",
                marginRight: "10px",
              }}
            >
              <img src={src} alt="Preview" style={{ height: "100px" }} />
              <button
              type="button"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  background: "red",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={() => handleImageDelete(index)}
              >
                X
              </button>
            </div>
          ))}
        </div>
      </FormGroup>
      <FormGroup>
        <Label for="video">Video</Label>
        <Input
          type="file"
          name="video"
          id="video"
          multiple
          onChange={handleVideoChange}
        />
        <div style={{ marginTop: "10px" }}>
          {videoPreviews.map((src, index) => (
            <div
              key={index}
              style={{
                display: "inline-block",
                position: "relative",
                marginRight: "10px",
              }}
            >
              <video
                src={src}
                alt="Video Preview"
                style={{ height: "100px" }}
                controls
              />
              <button
              type="button"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  background: "red",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={() => handleVideoDelete(index)}
              >
                X
              </button>
            </div>
          ))}
        </div>
      </FormGroup>
      <FormGroup>
        <Label for="logo">Showcase</Label>
        <Input
          type="file"
          name="logo"
          id="logo"
          multiple
          onChange={handleShowcaseChange}
        />
        <div style={{ marginTop: "10px" }}>
          {logoPreviews.map((src, index) => (
            <div
              key={index}
              style={{
                display: "inline-block",
                position: "relative",
                marginRight: "10px",
              }}
            >
              <img
                src={src}
                alt="Logo Preview"
                style={{ height: "100px" }}
              />
              <button
              type="button"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  background: "red",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={() => handleShowcaseDelete(index)}
              >
                X
              </button>
            </div>
          ))}
        </div>
      </FormGroup>
      <Button color="primary" type="submit" disabled={isLoading || uploadingMedia}>
        Submit
      </Button>
      {uploadingMedia && (
        <Alert color="primary">
          <Spinner /> Uploading {uploadingMedia} {uploadingIndex !== null && `+${uploadingIndex}`}, please wait...
        </Alert>
      )}
      <Progress value={uploadProgress} max={100} />
    </Form>
    );
}

export default UpdateArt;
