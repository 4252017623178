import React, { useState, useEffect } from "react";
import { Button, Input } from "reactstrap";
import { db } from "../../../firebase";
import firebase from "firebase/compat/app";
import { fetchDropdowns } from "../../utils/firebaseUtils.js";

const DropdownInput = ({ field, value, onChange, onSubmit }) => (
  <>
    <Input
      type="text"
      value={value}
      onChange={onChange}
      style={{ marginRight: "10px" }}
    />
    <Button className="primary" onClick={() => onSubmit(field)}>
      +
    </Button>
  </>
);

function DropdownManager({ firestoreDocument = "games", refreshDropdowns }) {
  const [dropdowns, setDropdowns] = useState({
    tools: [],
    media: [],
    type: [],
    keywords: [],
  });
  const [currentValue, setCurrentValue] = useState("");
  const [selectedField, setSelectedField] = useState(null);

  const fetchDropdowns = async () => {
    const doc = await db.collection("dropdowns").doc(firestoreDocument).get();
    if (doc.exists) {
      setDropdowns(doc.data());
    }
  };

  useEffect(() => {
    fetchDropdowns();
  }, [firestoreDocument]);

  const handleSubmit = async (field) => {
    // Fetch current dropdown values
    const doc = await db.collection("dropdowns").doc(firestoreDocument).get();
    if (!doc.exists) {
      console.error("Document does not exist!");
      return;
    }
    const dropdownData = doc.data();

    let valueToAdd = currentValue;

    if (valueToAdd) {
      if (dropdownData[field] && dropdownData[field].includes(valueToAdd)) {
        alert(`This ${field} already exists!`);
        return;
      }
      await db
        .collection("dropdowns")
        .doc(firestoreDocument)
        .update({
          [field]: firebase.firestore.FieldValue.arrayUnion(valueToAdd),
        });
      fetchDropdowns();
      // Reset the input field
      refreshDropdowns(); 
      setCurrentValue("");
    }
  };

  const handleDelete = async (field, value) => {
    if (!window.confirm("Are you sure you want to delete this?")) {
      return;
    }
    await db
      .collection("dropdowns")
      .doc(firestoreDocument)
      .update({
        [field]: firebase.firestore.FieldValue.arrayRemove(value),
      });
    setDropdowns((prev) => ({
      ...prev,
      [field]: prev[field].filter((item) => item !== value),
    }));
    refreshDropdowns(); 
  };

  const fixedOrder = ["type", "media", "tools", "keywords"];

  return (
    <div>
      {fixedOrder.map((key) => (
        <div className="mt-3" key={key}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <h4 style={{ marginRight: "10px" }}>
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </h4>
            <DropdownInput
              field={key}
              value={selectedField === key ? currentValue : ""}
              onChange={(e) => {
                setSelectedField(key);
                setCurrentValue(e.target.value);
              }}
              onSubmit={handleSubmit}
            />
          </div>
          <div>
            {dropdowns[key] &&
              dropdowns[key].map((value) => (
                <div
                  key={value}
                  style={{ display: "inline-block", margin: "5px" }}
                >
                  <Button className="primary">
                    {value}
                    <span
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                      onClick={() => handleDelete(key, value)}
                    >
                      X
                    </span>
                  </Button>
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default DropdownManager;
