import React, { useState, useEffect } from "react";
import {
  ListGroupItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit,
  faPlus,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { db } from "../../../firebase";
import AddArt from "./Add";
import UpdateArt from "./Update";
import Settings from "./settings/Settings";
import {
  fetchItems,
  fetchDropdowns,
  cleanupUnsubmittedMedia,
  deleteItem,
  toggleItemStatus,
} from "../../utils/firebaseUtils.js";
import Filters from "../../Filters";

function Apps({ addNotification }) {
  const [items, setItems] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false); // State for settings modal visibility
  const [filters, setFilters] = useState({ media: [], tools: [], type: [] });
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const firestoreCollection = "apps";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const itemsData = await fetchItems(firestoreCollection);
        setItems(itemsData);

        const dropdownResult = await fetchDropdowns(firestoreCollection);
        if (dropdownResult) {
          setFilters(dropdownResult.dropdownData);
          setSelectedFilters(dropdownResult.dynamicSelectedFilters);
        }

        cleanupUnsubmittedMedia();
      } catch (error) {
        console.error("Error fetching data:", error);
        addNotification(
          "Error fetching data. Please refresh the page.",
          "danger"
        );
      }
    };

    fetchData();
  }, []);

  const handleDeleteItem = () => {
    deleteItem(
      selectedItem,
      items,
      setItems,
      setSelectedItem,
      setShowDeleteConfirmation,
      addNotification,
      firestoreCollection
    );
  };

  const handleToggleItemStatus = (itemId, newStatus) => {
    toggleItemStatus(
      itemId,
      newStatus,
      items,
      setItems,
      addNotification,
      firestoreCollection
    );
  };

  const refreshItemsList = async (newItemId) => {
    if (newItemId) {
      // Fetch only the new item
      const newItemDoc = await db
        .collection(firestoreCollection)
        .doc(newItemId)
        .get();
      if (newItemDoc.exists) {
        const newItem = { ...newItemDoc.data(), id: newItemDoc.id };
        setItems((prevItems) => [...prevItems, newItem]);
      }
    } else {
      // Fetch all items
      const itemsData = await fetchItems(firestoreCollection);
      setItems(itemsData);
    }
  };

  const handleOpenSettingsModal = () => {
    setShowSettingsModal(true);
  };

  const handleCloseSettingsModal = () => {
    setShowSettingsModal(false);
  };

  const handleOpenUpdateModal = (item) => {
    setSelectedItem(item);
    setShowUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setSelectedItem(null);
    setShowUpdateModal(false);
  };

  const handleAddItem = () => {
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const showDeleteConfirmationModal = (item) => {
    setSelectedItem(item);
    setShowDeleteConfirmation(true);
  };

  function ItemSwitch({ isOn, onToggle }) {
    return (
      <FormGroup switch inline>
        <Input type="switch" checked={isOn} onChange={onToggle} />
      </FormGroup>
    );
  }

  const applyLocalFilters = () => {
    let filteredItems = items;

    Object.keys(selectedFilters).forEach((category) => {
      if (selectedFilters[category].length > 0) {
        filteredItems = filteredItems.filter((item) =>
          selectedFilters[category].every(
            (filter) => item[category] && item[category].includes(filter)
          )
        );
      }
    });

    return filteredItems;
  };

  return (
    <div>
      <div className="p-3">
        <h1 className="title">Apps Manager</h1>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "5vh",
          }}
          className="mt-2"
        >
          <Button className="primary" onClick={handleAddItem}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add
          </Button>
          <Button className="primary m-2" onClick={handleOpenSettingsModal}>
            <FontAwesomeIcon icon={faCog} /> Settings
          </Button>
        </div>
        <br />
        {/* Filters */}
        <Filters
          filters={filters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          items={items}
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
        />

        <hr className="font-2" />
      </div>
      <div className="list-group mt-4 list">
        <h4>My App List</h4>
        <hr />

        {/* listing items */}
        <div
          className="list"
          style={{ display: "flex", flexDirection: "column", marginTop: "2vh" }}
        >
          {applyLocalFilters().map((item) => (
            <div
              key={item.id}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              {/* Display the logo */}
              <div style={{ marginRight: "10px" }}>
                {item.LogoUrl && item.LogoUrl[0] && (
                  <img
                    src={item.LogoUrl[0]}
                    alt="Item Logo"
                    style={{ height: "50px", width: "50px" }}
                  />
                )}
              </div>

              {/* Display the item details */}
              <ListGroupItem
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 5,
                }}
                className="list-item item"
              >
                <span>{item.name}</span>

                {/* <span>{art.type}</span> */}

                {/* Display the switch */}
                <span>
                  <ItemSwitch
                    style={{
                      cursor: "pointer",
                      height: "25px",
                    }}
                    isOn={item.isOn}
                    onToggle={() => handleToggleItemStatus(item.id, !item.isOn)}
                  />
                </span>
              </ListGroupItem>

              {/* Display the buttons */}
              <div
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  padding: 0,
                }}
                className=""
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  className="mr-2"
                  style={{
                    cursor: "pointer",
                    height: "25px",
                    marginRight: "10px",
                  }}
                  onClick={() => showDeleteConfirmationModal(item)}
                />
                <FontAwesomeIcon
                  icon={faEdit}
                  style={{
                    cursor: "pointer",
                    height: "25px",
                    marginRight: "10px",
                  }}
                  className=""
                  onClick={() => handleOpenUpdateModal(item)}
                />
              </div>
            </div>
          ))}
        </div>

        <Modal isOpen={showAddModal} toggle={handleCloseAddModal}>
          <ModalHeader className="label" toggle={handleCloseAddModal}>
            Add
          </ModalHeader>
          <ModalBody className="footer">
            <AddArt
              addNotification={addNotification}
              refreshList={(newItemId) => refreshItemsList(newItemId)}
            />
          </ModalBody>
        </Modal>

        <Modal isOpen={showUpdateModal} toggle={handleCloseUpdateModal}>
          <ModalHeader className="label" toggle={handleCloseUpdateModal}>
            Update
          </ModalHeader>
          <ModalBody className="footer">
            {selectedItem && (
              <UpdateArt
                refreshList={refreshItemsList}
                addNotification={addNotification}
                itemData={selectedItem}
                onUpdateSuccess={() => {
                  handleCloseUpdateModal();
                }}
              />
            )}
          </ModalBody>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal
          className="label"
          isOpen={showDeleteConfirmation}
          toggle={() => setShowDeleteConfirmation(false)}
        >
          <ModalHeader
            className="label"
            toggle={() => setShowDeleteConfirmation(false)}
          >
            Confirm Deletion
          </ModalHeader>
          <ModalBody className="footer">
            Are you sure you want to delete this item?
          </ModalBody>
          <ModalFooter className="footer">
            <Button color="danger" onClick={handleDeleteItem}>
              Delete
            </Button>
            <Button
              color="secondary"
              onClick={() => setShowDeleteConfirmation(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className="label"
          isOpen={showSettingsModal}
          toggle={handleCloseSettingsModal}
        >
          <ModalHeader className="label" toggle={handleCloseSettingsModal}>
            Settings
          </ModalHeader>
          <ModalBody className="footer">
            <Settings />
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
}

export default Apps;
