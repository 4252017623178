import { Routes, Route } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./components/Dashboard.js";
import Login from "./components/pages/Login";
import AuthContext from "./components/AuthContext";
import Nav from "./components/Nav.js";
import "./App.scss";
import NotFound from "./components/pages/NotFound";
import Main from "./components/pages/Main";
import Loading from './components/pages/Loading.js';

function App() {
  const lightPalettes = ['tangerine-l', 'forest-l', 'arctic-l', 'grape-l', 'peach-l', 'coconut-l'];
  const darkPalettes = ['tangerine-d', 'forest-d', 'arctic-d', 'grape-d', 'peach-d', 'coconut-d'];
  const [mode, setMode] = useState('light'); 
  const [theme, setTheme] = useState(() => {
    const palettes = mode === 'light' ? lightPalettes : darkPalettes;
    return palettes[Math.floor(Math.random() * palettes.length)];
  });

  const toggleMode = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    setMode(newMode);
    const palettes = newMode === 'light' ? lightPalettes : darkPalettes;
    const randomPalette = palettes[Math.floor(Math.random() * palettes.length)];
    setTheme(randomPalette);
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <Loading />; // Or your loading spinner component
  }

  return (
    <AuthContext.Provider value={currentUser}>
      <Nav mode={mode} toggleMode={toggleMode} /> 
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/dashboard/*"
          element={
            <ProtectedRoute>
              <Main />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* <Nav mode={mode} toggleMode={toggleMode} />  */}
    </AuthContext.Provider>
  );
}

export default App;
