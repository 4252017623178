import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBYUXWujluW2HfktEnAjQv5YfkPS7uxygw",

  authDomain: "alpkurt-8e3c3.firebaseapp.com",

  projectId: "alpkurt-8e3c3",

  storageBucket: "alpkurt-8e3c3.appspot.com",

  messagingSenderId: "460224739335",

  appId: "1:460224739335:web:dd4c8233114db2e191d2a2",

  measurementId: "G-LLHWSLWKC9"

};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const storage = firebase.storage();
const auth = firebase.auth();

export { firebase, db, storage, auth };
