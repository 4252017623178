import React, { useState } from "react";
import { Button, FormGroup, Label, Input } from "reactstrap";
import { db } from "../../../../firebase";
import firebase from "firebase/compat/app";


function AddDropdown() {
  const [type, setType] = useState("");
  const [tool, setTool] = useState("");
  const [media, setMedium] = useState("");

  const handleSubmit = async (field) => {
    // Fetch current dropdown values
    const doc = await db.collection("dropdowns").doc("digitalArts").get();
    if (!doc.exists) {
      console.error("Document does not exist!");
      return;
    }
    const dropdownData = doc.data();
  
    if (field === "type" && type) {
      if (dropdownData.type && dropdownData.type.includes(type)) {
        alert("This type already exists!");
        return;
      }
      await db.collection("dropdowns").doc("digitalArts").update({
        type: firebase.firestore.FieldValue.arrayUnion(type),
      });
      setType("");
    } else if (field === "tool" && tool) {
      if (dropdownData.tools && dropdownData.tools.includes(tool)) {
        alert("This tool already exists!");
        return;
      }
      await db.collection("dropdowns").doc("digitalArts").update({
        tools: firebase.firestore.FieldValue.arrayUnion(tool),
      });
      setTool("");
    } else if (field === "media" && media) {
      if (dropdownData.media && dropdownData.media.includes(media)) {
        alert("This medium already exists!");
        return;
      }
      await db.collection("dropdowns").doc("digitalArts").update({
        media: firebase.firestore.FieldValue.arrayUnion(media),
      });
      setMedium("");
    }
  };

  return (
    <div>
      <FormGroup className="mt-3" style={{ display: "flex", alignItems: "center" }}>
        <Label for="type" style={{ flex: 1 }}>
          Type
        </Label>
        <Input
          type="text"
          name="type"
          id="type"
          value={type}
          onChange={(e) => setType(e.target.value)}
          style={{ flex: 2, marginRight: "10px" }}
        />
        <Button className="primary" onClick={() => handleSubmit("type")}>+</Button>
      </FormGroup>

      <FormGroup  style={{ display: "flex", alignItems: "center" }}>
        <Label for="tool" style={{ flex: 1 }}>
          Tool
        </Label>
        <Input
          type="text"
          name="tool"
          id="tool"
          value={tool}
          onChange={(e) => setTool(e.target.value)}
          style={{ flex: 2, marginRight: "10px" }}
        />
        <Button className="primary" onClick={() => handleSubmit("tool")}>+</Button>
      </FormGroup>

      <FormGroup style={{ display: "flex", alignItems: "center" }}>
        <Label for="media" style={{ flex: 1 }}>
          Medium
        </Label>
        <Input
          type="text"
          name="media"
          id="media"
          value={media}
          onChange={(e) => setMedium(e.target.value)}
          style={{ flex: 2, marginRight: "10px" }}
        />
        <Button className="primary" onClick={() => handleSubmit("media")}>+</Button>
      </FormGroup>
    </div>
  );
}

export default AddDropdown;
