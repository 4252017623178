import { db, storage, firebase } from "../../firebase"; 

/**
 * Add a media URL to the unsubmittedMediaUrls document.
 * @param {string} url - The media URL to add.
 */
export const addToUnsubmittedMediaUrls = async (url) => {
    try {
        const docRef = db.collection("temp").doc("unsubmittedMediaUrls");
        await docRef.update({
            urls: firebase.firestore.FieldValue.arrayUnion(url),
        });
    } catch (error) {
        console.error("Error adding URL to unsubmittedMediaUrls:", error);
        throw error; // Re-throwing the error so it can be caught and handled by the calling function/component
    }
};

/**
 * Remove media URLs from the unsubmittedMediaUrls document.
 * @param {Array<string>} urls - The array of media URLs to remove.
 */
export const removeFromUnsubmittedMediaUrls = async (urls) => {
    try {
        const docRef = db.collection("temp").doc("unsubmittedMediaUrls");
        await docRef.update({
            urls: firebase.firestore.FieldValue.arrayRemove(...urls),
        });
    } catch (error) {
        console.error("Error removing URLs from unsubmittedMediaUrls:", error);
        throw error; // Re-throwing the error so it can be caught and handled by the calling function/component
    }
};

/**
 * Delete a media file from Firebase storage.
 * @param {string} url - The media URL to delete.
 */
export const deleteFromStorage = async (url) => {
    try {
        const fileRef = storage.refFromURL(url);
        await fileRef.delete();
    } catch (error) {
        console.error("Error deleting media from storage:", error);
        throw error; // Re-throwing the error so it can be caught and handled by the calling function/component
    }
};

/**
 * Generate a unique file name based on the current timestamp, type, and index.
 * @param {File} file - The file for which the name is being generated.
 * @param {string} type - The type of media (e.g., "images", "videos").
 * @param {number} index - The index of the file.
 * @param {string} firestoreCollection - The Firestore collection name.
 * @returns {string} - The generated file name.
 */
export const generateFileName = (file, type, index, firestoreCollection) => {
    const getCurrentTimestamp = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JS
        const day = String(now.getDate()).padStart(2, "0");
        const hour = String(now.getHours()).padStart(2, "0");
        const minute = String(now.getMinutes()).padStart(2, "0");
        return `${year}${month}${day}${hour}${minute}`;
    };

    const getCurrentSecond = () => {
        const now = new Date();
        const second = String(now.getSeconds()).padStart(2, "0");
        return `${second}`;
    };

    const timestamp = getCurrentTimestamp();
    const extension = file.name.split(".").pop();
    const currentSecond = getCurrentSecond();
    return `${firestoreCollection}/${type}/${timestamp}/${type}-${index}-${currentSecond}.${extension}`;
};
