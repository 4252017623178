import React, { useState } from 'react';
import {faFilter} from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CustomAccordion({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="custom-accordion">
      <div className="accordion-header"  onClick={() => setIsOpen(!isOpen)}>
     <div style={{verticalAlign:"center", textAlign:"center", alignItems:"center", display:"flex"}}><FontAwesomeIcon icon={faFilter} className='m-2 font-2' /> <h5>{title}</h5> 
        </div> 
      </div>
      {isOpen && <div className="accordion-body">{children}</div>}
    </div>
  );
}

export default CustomAccordion;
